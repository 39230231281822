html,
body,
#root {
  height: 100%;
}

.pf-v6-c-content {
  --pf-v6-c-content--small--Color: red; /* changes all <small> color to --pf-v5-global--palette--green-500 */
  --pf-v6-c-content--blockquote--BorderLeftColor: purple; /* changes all <blockquote> left border color to --pf-v5-global--palette--cyan-600 */
  --pf-v6-c-content--hr--BackgroundColor: yellow; /* changes a <hr> color to --pf-v5-global--palette--gold-500 */
}
